import {
  COURSE_LOAD,
  COURSES_LOAD,
  COURSE_POST,
  COURSE_PATCH,
  COURSE_DELETE,
  COURSE_CLEAR,
  COURSE_ERROR,
} from '../actions/types';

const initialState = {
  courses: [],
  courseStates: [],
  course: {},
};

export default function course(state = initialState, action) {
  const { type, payload } = action;
  const { courses } = state;
  switch (type) {
    case COURSES_LOAD:
      return {
        ...state,
        courses: [...payload.courses],
        courseStates: [...payload.courseStates],
      };
    case COURSE_LOAD:
      return {
        ...state,
        course: payload,
      };
    case COURSE_POST:
      return { ...state, courses: [...courses, payload] };
    case COURSE_DELETE:
      return {
        ...state,
        courses: courses.filter(({ _id }) => _id !== payload),
      };
    case COURSE_CLEAR:
      return initialState;
    case COURSE_PATCH:
      return {
        ...state,
        courses: [...courses.filter(({ _id }) => _id !== payload._id), payload],
        course: payload,
      };
    case COURSE_ERROR:
      return state;
    default:
      return state;
  }
}
