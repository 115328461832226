import {
  AUTH_LOAD_USER,
  AUTH_LOAD_USER_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  AUTH_UPDATE_PROFILEPIC,
  AUTH_EMAIL_CONFIRMATION,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  userStates: {},
  files: {},
  hasValidLink: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOAD_USER:
      return {
        ...state,
        userStates: payload.userStates,
        files: payload.files,
        isAuthenticated: true,
        loading: false,
        user: {
          ...payload.user,
          driveFolderId: payload.driveFolderId,
        },
        token: payload.token,
      };
    case AUTH_LOAD_USER_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    case AUTH_LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        userStates: payload.userStates,
        files: payload.files,
        user: {
          ...payload.user,
          driveFolderId: payload.driveFolderId,
        },
        loading: false,
      };
    case AUTH_LOGIN_ERROR:
      localStorage.removeItem('token');
      return {
        ...initialState,
      };
    case AUTH_LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return {
        ...initialState,
        loading: false,
      };
    case AUTH_LOGOUT_ERROR:
      return state;
    case AUTH_EMAIL_CONFIRMATION:
      return { ...state, hasValidLink: payload };
    case AUTH_UPDATE_PROFILEPIC:
      // FIXME : This has nothing to do in auth.js, we must create user.js
      // and keep auth.js to the minimal
      return {
        ...state,
        user: { ...state.user, profilepic: payload },
      };
    default:
      return state;
  }
}
