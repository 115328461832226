import {
  OTHER_PAGES_LOAD,
  OTHER_PAGE_POST,
  OTHER_PAGE_PATCH,
  OTHER_PAGE_DELETE,
  OTHER_PAGE_ERROR,
  OTHER_PAGE_CLEAR,
} from '../actions/types';

const initialState = {
  otherpages: [],
};

export default function otherPage(state = initialState, action) {
  const { type, payload } = action;
  const { otherpages } = state;

  switch (type) {
    case OTHER_PAGES_LOAD:
      return { ...state, otherpages: payload };
    case OTHER_PAGE_POST:
      return { ...state, otherpages: [...otherpages, payload] };
    case OTHER_PAGE_DELETE:
      return {
        ...state,
        otherpages: otherpages.filter(({ _id }) => _id !== payload),
      };
    case OTHER_PAGE_CLEAR:
      return initialState;
    case OTHER_PAGE_PATCH:
      return {
        ...state,
        otherpages: [
          ...otherpages.filter(({ _id }) => _id !== payload._id),
          payload,
        ],
      };
    case OTHER_PAGE_ERROR:
      return state;
    default:
      return state;
  }
}
