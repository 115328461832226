import {
  TEXT_LOAD,
  TEXT_ADMIN_LOAD,
  TEXT_POST,
  TEXT_PATCH,
  TEXT_DELETE,
  TEXT_ERROR,
  TEXT_CLEAR,
} from '../actions/types';

const initialState = {
  texts: [],
  pagesEnum: [],
  textNamesEnum: [],
};

/*
 * We do this because of Footer which is loaded multiple times when
 * moving from page to page.
 */
const removeDuplicates = (texts) => {
  const textNamesArr = texts.map((text) => text.name);
  return texts.filter(({ name }, index) => {
    const indexOf = textNamesArr.indexOf(name);
    return index === indexOf;
  });
};

export default function text(state = initialState, action) {
  const { type, payload } = action;
  const { texts } = state;

  switch (type) {
    case TEXT_LOAD:
      return { ...state, texts: removeDuplicates([...texts, ...payload]) };
    case TEXT_ADMIN_LOAD:
      return { ...state, ...payload };
    case TEXT_POST:
      return { ...state, texts: [...texts, payload] };
    case TEXT_PATCH:
      return {
        ...state,
        texts: [...texts.filter(({ _id }) => _id !== payload._id), payload],
      };
    case TEXT_DELETE:
      return { ...state, texts: texts.filter(({ _id }) => _id !== payload) };
    case TEXT_CLEAR:
      return initialState;
    case TEXT_ERROR:
      return state;
    default:
      return state;
  }
}
