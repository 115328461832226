import {
  REGISTERING_GENERATE_LINK_SUCCESS,
  REGISTERING_GENERATE_LINK_ERROR,
  REGISTERING_CHECK_LINK_SUCCESS,
  REGISTERING_CHECK_LINK_ERROR,
  REGISTERING_SUCCESS,
  REGISTERING_CLEAR,
} from '../actions/types';

const initialState = {
  loading: true,
  validRegistrationLink: null,
  registeringDone: null,
};

export default function registering(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case REGISTERING_CHECK_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        validRegistrationLink: true,
      };
    case REGISTERING_CHECK_LINK_ERROR:
      return {
        ...state,
        loading: false,
        validRegistrationLink: false,
      };
    case REGISTERING_SUCCESS:
      return {
        ...state,
        loading: false,
        registeringDone: true,
      };
    case REGISTERING_CLEAR:
      return {
        ...initialState,
      };
    case REGISTERING_GENERATE_LINK_SUCCESS:
    case REGISTERING_GENERATE_LINK_ERROR:
    default:
      return state;
  }
}
