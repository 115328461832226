import {
  PASSWORD_RESET_GENERATE_LINK_SUCCESS,
  PASSWORD_RESET_GENERATE_LINK_ERROR,
  PASSWORD_RESET_CHECK_LINK_SUCCESS,
  PASSWORD_RESET_CHECK_LINK_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_CLEAR,
} from '../actions/types';

const initialState = {
  loading: true,
  validResetLink: null,
  passwordReset: null,
};

export default function password(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case PASSWORD_RESET_CHECK_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        validResetLink: true,
      };
    case PASSWORD_RESET_CHECK_LINK_ERROR:
      return {
        ...state,
        loading: false,
        validResetLink: false,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordReset: true,
      };
    case PASSWORD_RESET_CLEAR:
      return {
        ...initialState,
      };
    case PASSWORD_RESET_GENERATE_LINK_SUCCESS:
    case PASSWORD_RESET_GENERATE_LINK_ERROR:
      return state;
    default:
      return state;
  }
}
