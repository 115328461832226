import {
  FIELDS_LOAD,
  FIELD_POST,
  FIELD_PATCH,
  FIELD_DELETE,
  FIELD_ERROR,
  FIELD_CLEAR,
} from '../actions/types';

const initialState = {
  fields: [],
};

export default function field(state = initialState, action) {
  const { type, payload } = action;
  const { fields } = state;

  switch (type) {
    case FIELDS_LOAD:
      return {
        ...state,
        fields: [...payload],
      };
    case FIELD_POST:
      return { ...state, fields: [...fields, payload] };
    case FIELD_DELETE:
      return {
        ...state,
        fields: fields.filter(({ _id }) => _id !== payload),
      };
    case FIELD_CLEAR:
      return initialState;
    case FIELD_PATCH:
      return {
        ...state,
        fields: [...fields.filter(({ _id }) => _id !== payload._id), payload],
      };
    case FIELD_ERROR:
      return state;
    default:
      return state;
  }
}
