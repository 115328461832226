import { SOCKET_SAVE, SOCKET_CLEAR } from '../actions/types';

const initialState = {
  socket: null,
};

export default function role(state = initialState, action) {
  const { type, payload } = action;
  const { socket } = state;

  switch (type) {
    case SOCKET_SAVE:
      return {
        ...state,
        socket: payload,
      };
    case SOCKET_CLEAR:
      socket.disconnect();
      return initialState;
    default:
      return state;
  }
}
