import {
  LOAN_LOAD,
  LOAN_POST,
  LOAN_PATCH,
  LOAN_ERROR,
  LOAN_CLEAR,
} from '../actions/types';

const initialState = {
  loans: [],
  count:0,
};

export default function loan(state = initialState, action) {
  const { type, payload } = action;
  const { loans } = state;

  switch (type) {
    case LOAN_LOAD:
      return { ...state, ...payload };
    case LOAN_POST:
      return { ...state, loans: [...loans, payload] };
    case LOAN_PATCH:
      return {
        ...state,
        loans: [...loans.filter(({ _id }) => _id !== payload._id), payload],
      };
    case LOAN_CLEAR:
      return initialState;
    case LOAN_ERROR:
      return state;
    default:
      return state;
  }
}
