import {
  BOOKS_LOAD,
  BOOK_POST,
  BOOK_PATCH,
  BOOK_DELETE,
  BOOK_ERROR,
  BOOK_CLEAR,
} from '../actions/types';

const initialState = {
  books: [],
  count: 0,
};

export default function book(state = initialState, action) {
  const { type, payload } = action;
  const { books } = state;

  switch (type) {
    case BOOKS_LOAD:
      return {
        ...payload,
        books: payload.books,
      };
    case BOOK_POST:
      return { ...state, books: [...books, payload] };
    case BOOK_DELETE:
      return {
        ...state,
        books: books.filter(({ _id }) => _id !== payload),
      };
    case BOOK_CLEAR:
      return initialState;
    case BOOK_PATCH:
      return {
        ...state,
        books: [...books.filter(({ _id }) => _id !== payload._id), payload],
      };
    case BOOK_ERROR:
      return state;
    default:
      return state;
  }
}
