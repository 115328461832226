import axios from 'axios';
import { toast } from 'react-toastify';
import { saveSocket } from './socket';

import {
  AUTH_LOAD_USER,
  AUTH_LOAD_USER_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  AUTH_UPDATE_PROFILEPIC,
  AUTH_EMAIL_CONFIRMATION,
  AUTH_GENERATION_EMAIL_CONFIRMATION,
} from './types';

import setAuthToken from '../utils/setAuthToken';
import { clearUsers } from './user';
import { checkPermission } from './permission';
import { textClearAction } from './text';
import { loadRoles } from './role';
import { loadMe } from './student';

// Action creators
const userLoadedAction = (res) => {
  return {
    type: AUTH_LOAD_USER,
    payload: res.data,
  };
};

const authErrorAction = (err) => {
  return {
    type: AUTH_LOAD_USER_ERROR,
  };
};

const loginAction = (res) => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: res.data,
  };
};

const loginErrorAction = (msg) => {
  toast.error(msg);
  return {
    type: AUTH_LOGIN_ERROR,
  };
};

export const logoutAction = () => {
  return {
    type: AUTH_LOGOUT_SUCCESS,
  };
};

const logoutErrorAction = (err) => {
  toast.warning('Erreur de déconnexion');
  return {
    type: AUTH_LOGOUT_ERROR,
  };
};

const emailConfirmationAction = (payload, shouldPassTest) => {
  const msg =
    'Connectez vous pour remplir votre dossier en ligne et le soumettre';

  if (payload && shouldPassTest !== null)
    toast.success(
      `Votre compte a été confirmé. 
      ${msg}`
    );
  return {
    type: AUTH_EMAIL_CONFIRMATION,
    payload,
  };
};

const generateEmailConfirmationAction = (msg) => {
  toast.success('Lien regénéré et envoyé');
  return {
    type: AUTH_GENERATION_EMAIL_CONFIRMATION,
  };
};

// FIXME : This has nothing to do in auth.js, we must create user.js
// and keep auth.js to the minimal
const updateProfilePicAction = (profilepic) => {
  return {
    type: AUTH_UPDATE_PROFILEPIC,
    payload: profilepic,
  };
};

// Operations
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    await axios
      .post('/api/users/auth')
      .then((res) => {
        if (!res.data.user.role.includes('admin')) {
          dispatch(saveSocket(res.data.user._id));
        }

        if (res.data.user.role === 'student') {
          dispatch(loadMe());
        }

        dispatch(userLoadedAction(res));

        if (res.data.user.role.includes('admin')) {
          dispatch(loadRoles());

          dispatch(checkPermission(res.data.user));
        }
      })
      .catch((err) => {
        dispatch(authErrorAction(err));
      });
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, password });
  await axios
    .post('/api/users/login', body, config)
    .then((res) => {
      dispatch(loginAction(res));
      dispatch(loadUser());
    })
    .catch(
      ({
        response: {
          status,
          data: { error },
        },
      }) => {
        let msg = 'Echec de connexion !';
        dispatch(loginErrorAction(msg));
      }
    );
};

export const logout = (user) => async (dispatch) => {
  await axios
    .post('/api/users/logout')
    .then((res) => {
      if (user.role.includes('admin')) {
        dispatch(clearUsers());
        dispatch(textClearAction());
      }
      dispatch(logoutAction(res));
    })
    .catch((err) => {
      dispatch(logoutErrorAction(err));
    });
};

export const emailConfirmationLinkCheck = (token) => (dispatch) => {
  axios
    .patch(`/api/users/emailConfirmation/${token}`)
    .then((res) => {
      const shouldPassTest = res.data.details.shouldPassTest;
      dispatch(emailConfirmationAction(true, shouldPassTest));
      dispatch(loadUser());
    })
    .catch((err) => {
      if (err.response.data.error.includes('jwt expired')) {
        dispatch(emailConfirmationAction(false));
      } else {
        dispatch(emailConfirmationAction(true, null));
        dispatch(loginErrorAction('Votre lien est invalide'));
      }
    });
};

export const generateEmailConfirmationLink = (token) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ token });
  axios
    .post('/api/users/emailConfirmation', body, config)
    .then((res) => {
      dispatch(generateEmailConfirmationAction(res));
    })
    .catch((err) => {
      dispatch(loginErrorAction());
    });
};

// FIXME : This has nothing to do in auth.js, we must create user.js
// and keep auth.js to the minimal
export const updateProfilePic = (profilepic) => async (dispatch) => {
  dispatch(updateProfilePicAction(profilepic));
};
