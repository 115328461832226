import {
  FAQ_LOAD,
  FAQ_ADMIN_LOAD,
  FAQ_POST,
  FAQ_PATCH,
  FAQ_DELETE,
  FAQ_CLEAR,
  FAQ_ERROR,
} from '../actions/types';

const initialState = {
  faqs: [],
  userGroupsEnum: [],
};

export default function faq(state = initialState, action) {
  const { type, payload } = action;
  const { faqs } = state;

  switch (type) {
    case FAQ_LOAD:
      return { ...state, faqs: payload };
    case FAQ_ADMIN_LOAD:
      return { ...state, ...payload };
    case FAQ_POST:
      return { ...state, faqs: [...faqs, payload] };
    case FAQ_DELETE:
      return { ...state, faqs: faqs.filter(({ _id }) => _id !== payload) };
    case FAQ_CLEAR:
      return initialState;
    case FAQ_PATCH:
      return {
        ...state,
        faqs: [...faqs.filter(({ _id }) => _id !== payload._id), payload],
      };
    case FAQ_ERROR:
      return state;
    default:
      return state;
  }
}
