import {
  STUDENT_REGISTRATION_INIT,
  STUDENT_REGISTRATION_CLEAR,
  STUDENT_SINGLE_LOAD,
  STUDENT_SINGLE_CLEAR,
} from '../actions/types';

const initialState = {
  details: {},
  email: '',
  field: '',
  firstname: '',
  lastname: '',
  level: '',
  phoneNumber: '',
  profilepic: '',
  role: '',
  state: '',
};

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STUDENT_SINGLE_LOAD:
      return payload;
    case STUDENT_SINGLE_CLEAR:
    case STUDENT_REGISTRATION_CLEAR:
      return initialState;
    case STUDENT_REGISTRATION_INIT:
      return payload;
    default:
      return state;
  }
}
