import {
  EMPLOYEE_LOAD,
  EMPLOYEE_POST,
  EMPLOYEE_PATCH,
  EMPLOYEE_DELETE,
  EMPLOYEE_ERROR,
  EMPLOYEE_CLEAR,
} from '../actions/types';

const initialState = {
  employees: [],
  servicesEnum: [],
};

export default function employee(state = initialState, action) {
  const { type, payload } = action;
  const { employees } = state;

  switch (type) {
    case EMPLOYEE_LOAD:
      return { ...state, ...payload };
    case EMPLOYEE_POST:
      return { ...state, employees: [...employees, payload] };
    case EMPLOYEE_DELETE:
      return {
        ...state,
        employees: employees.filter(({ _id }) => _id !== payload),
      };
    case EMPLOYEE_PATCH:
      return {
        ...state,
        employees: [
          ...employees.filter(({ _id }) => _id !== payload._id),
          payload,
        ],
      };
    case EMPLOYEE_CLEAR:
      return initialState;
    case EMPLOYEE_ERROR:
      return state;
    default:
      return state;
  }
}
