import {
  COMPOSITION_LOAD,
  COMPOSITION_STUDENT_LOAD,
  COMPOSITION_POST,
  COMPOSITION_PATCH,
  COMPOSITION_ERROR,
  COMPOSITION_ADDING,
  COMPOSITION_SELECT,
  COMPOSITION_CLEAR,
  COMPOSITION_STUDENT_REMOVE,
  COMPOSITION_REMOVE_FROM_STUDENT_PROFIL,
  COMPOSITION_STUDENT_DISTRIBUTE,
  COMPOSITION_CODE_GEN,
} from '../actions/types';

const sortingById = (data) => {
  return data.sort((a, b) => {
    let fa = a._id.toLowerCase(),
      fb = b._id.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};

const initialState = {
  compositions: [],
  studentProfilCompositions: [],
  selectedCompositionStudents: [],
};

const returnSelectedCompositionStudents = (isDistributed, students) => {
  if (!isDistributed) {
    const getClasses = students.map(({ studentId }) => studentId.classroom);
    const classroomList = getClasses.filter(
      (item, index) => getClasses.indexOf(item) === index
    );

    return students.filter((student) =>
      classroomList.includes(student.studentId.classroom)
    );
  }
  return students;
};

export default function composition(state = initialState, action) {
  const { type, payload } = action;
  const {
    compositions,
    selectedCompositionStudents,
    studentProfilCompositions,
  } = state;
  switch (type) {
    case COMPOSITION_LOAD:
      return {
        ...state,
        compositions: payload,
        selectedCompositionStudents: [],
      };
    case COMPOSITION_STUDENT_LOAD:
      return {
        ...state,
        studentProfilCompositions: payload,
      };
    case COMPOSITION_STUDENT_REMOVE:
      return {
        ...state,
        selectedCompositionStudents: selectedCompositionStudents.filter(
          ({ studentId }) => studentId._id !== payload
        ),
      };
    case COMPOSITION_REMOVE_FROM_STUDENT_PROFIL:
      return {
        ...state,
        studentProfilCompositions: studentProfilCompositions.filter(
          ({ _id }) => _id !== payload
        ),
      };
    case COMPOSITION_POST:
      return {
        ...state,
        compositions: sortingById([...compositions, payload]),
      };
    case COMPOSITION_CLEAR:
      return initialState;
    case COMPOSITION_PATCH:
      return {
        ...state,
        compositions: sortingById([
          ...compositions.filter(({ _id }) => _id !== payload._id),
          payload,
        ]),
      };
    case COMPOSITION_SELECT:
      const composition = state.compositions.filter(
        ({ _id }) => _id === payload
      )[0];
      return {
        ...state,
        selectedCompositionStudents: sortingById(
          returnSelectedCompositionStudents(
            composition.isDistributed,
            composition.students
          ).map((student) => ({
            ...student,
            compositionId: composition._id,
          }))
        ),
      };
    case COMPOSITION_ADDING:
      return {
        ...state,
        studentProfilCompositions: [
          ...state.studentProfilCompositions,
          ...payload,
        ],
      };
    case COMPOSITION_CODE_GEN:
      const updateCodes = state.selectedCompositionStudents.map((student) => {
        const code = payload.students.filter(
          (std) => std._id === student._id
        )[0].code;

        return {
          ...student,
          code,
        };
      });

      return {
        ...state,
        selectedCompositionStudents: updateCodes,
      };
    case COMPOSITION_STUDENT_DISTRIBUTE:
      const updateRooms = state.selectedCompositionStudents.map((student) => {
        const testroom = payload.students.filter(
          (std) => std._id === student._id
        )[0].testroom;

        return {
          ...student,
          testroom,
        };
      });

      return {
        ...state,
        selectedCompositionStudents: updateRooms,
      };

    case COMPOSITION_ERROR:
      return state;
    default:
      return state;
  }
}
