import {
  PHOTOGALLERY_LOAD,
  PHOTOGALLERY_LOADING,
  PHOTOGALLERY_ADMIN_LOAD,
  PHOTOGALLERY_POST,
  PHOTOGALLERY_PATCH,
  PHOTOGALLERY_DELETE,
  PHOTOGALLERY_ERROR,
  PHOTOGALLERY_CLEAR,
} from '../actions/types';

const initialState = {
  photos: [],
  loading: false,
  count: 0,
};

const getDisplayableImages = (photos) =>
  photos.map((photogallery) =>
    Object.assign({}, photogallery, {
      key: photogallery.alt,
      src: photogallery.url,
    })
  );

const sortPhotos = (photos) =>
  photos.sort((photoA, photoB) => {
    return photoA.order - photoB.order;
  });

export default function photoGallery(state = initialState, action) {
  const { type, payload } = action;
  const { photos, count } = state;

  switch (type) {
    case PHOTOGALLERY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PHOTOGALLERY_LOAD:
      return {
        ...state,
        loading: false,
        count: payload.count,
        photos: sortPhotos([
          ...photos,
          ...getDisplayableImages(payload.photos),
        ]),
      };
    case PHOTOGALLERY_ADMIN_LOAD:
      return {
        ...state,
        loading: false,
        count: payload.count,
        photos: sortPhotos(payload.photos),
      };
    case PHOTOGALLERY_POST:
      return {
        ...state,
        count: count + 1,
        photos: sortPhotos([...photos, payload]),
      };
    case PHOTOGALLERY_PATCH:
      return {
        ...state,
        photos: sortPhotos([
          ...photos.filter(({ _id }) => _id !== payload._id),
          payload,
        ]),
      };
    case PHOTOGALLERY_DELETE:
      return {
        ...state,
        photos: sortPhotos(photos.filter(({ _id }) => _id !== payload)),
      };
    case PHOTOGALLERY_CLEAR:
      return initialState;
    case PHOTOGALLERY_ERROR:
      return state;
    default:
      return state;
  }
}
