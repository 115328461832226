import {
  EVENT_LOAD,
  EVENT_POST,
  EVENT_PATCH,
  EVENT_DELETE,
  EVENT_CLEAR,
  EVENT_ERROR,
} from '../actions/types';

const initialState = {
  events: [],
  isFuture: false,
};

export default function event(state = initialState, action) {
  const { type, payload } = action;
  const { events } = state;

  switch (type) {
    case EVENT_LOAD:
      return payload;
    case EVENT_POST:
      return { ...state, events: [...events, payload] };
    case EVENT_DELETE:
      return { ...state, events: events.filter(({ _id }) => _id !== payload) };
    case EVENT_CLEAR:
      return initialState;
    case EVENT_PATCH:
      return {
        ...state,
        events: [...events.filter(({ _id }) => _id !== payload._id), payload],
      };
    case EVENT_ERROR:
      return state;
    default:
      return state;
  }
}
