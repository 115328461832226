import {
  ALUMNIS_LOAD,
  ALUMNI_POST,
  ALUMNI_ERROR,
  ALUMNIS_STATS_LOAD,
} from '../actions/types';

const initialState = {
  alumnis: [],
  alumnisStatistics: [],
  count: 0,
};

export default function alumni(state = initialState, action) {
  const { type, payload } = action;
  const { alumnis } = state;

  switch (type) {
    case ALUMNIS_LOAD:
      return { ...state, ...payload };
    case ALUMNIS_STATS_LOAD:
      return { ...state, alumnisStatistics: payload };
    case ALUMNI_POST:
      return { ...state, alumnis: [...alumnis, payload] };
    case ALUMNI_ERROR:
      return state;
    default:
      return state;
  }
}
