import { io } from 'socket.io-client';
import { environments } from '../utils/enums';
import { SOCKET_SAVE, SOCKET_CLEAR } from './types';

export const saveSocketAction = (socket) => {
  return {
    type: SOCKET_SAVE,
    payload: socket,
  };
};
const clearSockectAction = () => {
  return {
    type: SOCKET_CLEAR,
  };
};

const getHost = () => {
  if (process.env.NODE_ENV === environments.PROD) {
    return 'https://esgcvak.com';
  }
  if (process.env.NODE_ENV === environments.STAGING) {
    return 'https://esgcvak-staging.herokuapp.com';
  }
  return `http://localhost:${port}`;
}
const defaultLocalPort = 5000;
const port = process.env.PORT || defaultLocalPort;
const host = getHost();
export const saveSocket = (userId) => (dispatch) => {
  const socket = io(host);
  socket.emit('Enter Conversation', userId);
  dispatch(saveSocketAction(socket));
};

export const clearSocket = () => (dispatch) => {
  dispatch(clearSockectAction());
};
