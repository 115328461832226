import {
  CYCLE_FORMATION_LOAD,
  CYCLE_FORMATION_POST,
  CYCLE_FORMATION_PATCH,
  CYCLE_FORMATION_ERROR,
  CYCLE_FORMATION_CLEAR,
} from '../actions/types';

const initialState = {
  cycleformations: [],
};

export default function cycleFormation(state = initialState, action) {
  const { type, payload } = action;
  const { cycleformations } = state;
  switch (type) {
    case CYCLE_FORMATION_LOAD:
      return { ...state, cycleformations: payload };
    case CYCLE_FORMATION_POST:
      return { ...state, cycleformations: [...cycleformations, payload] };
    case CYCLE_FORMATION_CLEAR:
      return initialState;
    case CYCLE_FORMATION_PATCH:
      return {
        ...state,
        cycleformations: [
          ...cycleformations.filter(({ _id }) => _id !== payload._id),
          payload,
        ],
      };
    case CYCLE_FORMATION_ERROR:
      return state;
    default:
      return state;
  }
}
