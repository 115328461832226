import {
  ARTICLE_LOAD,
  ARTICLES_LOAD,
  ARTICLE_POST,
  ARTICLE_PATCH,
  ARTICLE_DELETE,
  ARTICLE_ERROR,
  ARTICLE_CLEAR,
} from '../actions/types';

const initialState = {
  articles: [],
  displayedArticle: null,
  count: 0,
};

export default function article(state = initialState, action) {
  const { type, payload, isAdmin } = action;
  const { articles } = state;

  switch (type) {
    case ARTICLE_LOAD:
      return { ...state, displayedArticle: payload };
    case ARTICLES_LOAD:
      if (isAdmin) {
        return { ...state, ...payload };
      }
      return {
        ...payload,
        articles: [...articles, ...payload.articles],
      };
    case ARTICLE_POST:
      return { ...state, articles: [...articles, payload] };
    case ARTICLE_DELETE:
      return {
        ...state,
        articles: articles.filter(({ _id }) => _id !== payload),
      };
    case ARTICLE_CLEAR:
      return initialState;
    case ARTICLE_PATCH:
      return {
        ...state,
        articles: [
          ...articles.filter(({ _id }) => _id !== payload._id),
          payload,
        ],
      };
    case ARTICLE_ERROR:
      return state;
    default:
      return state;
  }
}
