import {
  HISTORY_LOAD,
  HISTORY_ERROR,
  HISTORY_CLEAR,
  HISTORY_LOADING,
} from '../actions/types';

const initialState = {
  loading: false,
  histories: [],
  actions: [],
  models: [],
  count: 0,
};

export default function role(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HISTORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HISTORY_LOAD:
      return { ...state, ...payload };
    case HISTORY_CLEAR:
      return initialState;
    case HISTORY_ERROR:
      return state;
    default:
      return state;
  }
}
