import {
  REGISTRATION_LOAD,
  REGISTRATION_PATCH,
  REGISTRATION_ERROR,
  REGISTRATION_CLEAR,
  REGISTRATION_SET_LOADING,
} from '../actions/types';

const initialState = {
  registrationNumbers: [],
  count: 0,
  isLoading: false,
};

export default function loan(state = initialState, action) {
  const { type, payload } = action;
  const { registrationNumbers } = state;

  switch (type) {
    case REGISTRATION_LOAD:
      return { ...state, ...payload };
    case REGISTRATION_PATCH:
      return {
        ...state,
        registrationNumbers: [
          payload,
          ...registrationNumbers.filter(({ _id }) => _id !== payload._id),
        ],
      };
    case REGISTRATION_SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case REGISTRATION_CLEAR:
      return initialState;
    case REGISTRATION_ERROR:
      return state;
    default:
      return state;
  }
}
