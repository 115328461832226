import {
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_CLEAR,
} from '../actions/types';

const initialState = {
  submitted: false,
};

export default function account(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case ACCOUNT_UPDATE:
      return {
        ...state,
        submitted: true,
      };
    case ACCOUNT_UPDATE_ERROR:
      return {
        ...state,
        submitted: true,
      };
    case ACCOUNT_UPDATE_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
