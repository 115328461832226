import axios from 'axios';
import { toast } from 'react-toastify';
import {
  adminLoadErrorMsg,
  adminPostErrorMsg,
  adminPostSuccessMsg,
  adminPatchErrorMsg,
  adminPatchSuccessMsg,
  adminDeleteErrorMsg,
  adminDeleteSuccessMsg,
} from './alertUtils';

import {
  TEXT_LOAD,
  TEXT_ADMIN_LOAD,
  TEXT_POST,
  TEXT_PATCH,
  TEXT_DELETE,
  TEXT_ERROR,
  TEXT_CLEAR,
} from './types';

const modelName = 'Texte';
const alertInsert = `du '${modelName.toLowerCase()}`;

const textLoadAction = (loadedTexts) => {
  return {
    type: TEXT_LOAD,
    payload: loadedTexts,
  };
};
const textAdminLoadAction = ({ texts, pagesEnum, textNamesEnum }) => {
  return {
    type: TEXT_ADMIN_LOAD,
    payload: { texts, pagesEnum, textNamesEnum },
  };
};
const textPostAction = (postedText) => {
  toast.success(adminPostSuccessMsg(alertInsert));
  return {
    type: TEXT_POST,
    payload: postedText,
  };
};
const textPatchAction = (patchedText) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: TEXT_PATCH,
    payload: patchedText,
  };
};
const textDeleteAction = (deletedTextId) => {
  toast.success(adminDeleteSuccessMsg(alertInsert));
  return {
    type: TEXT_DELETE,
    payload: deletedTextId,
  };
};
const textErrorAction = (msg) => {
  toast.error(msg);
  return {
    type: TEXT_ERROR,
  };
};
export const textClearAction = () => {
  return {
    type: TEXT_CLEAR,
  };
};

export const loadTexts = (page) => async (dispatch) => {
  await axios
    .get(`/api/texts/${page}`)
    .then((res) => {
      dispatch(textLoadAction(res.data));
    })
    .catch((_) => {
      // No alerts for no-admin users
    });
};

export const loadAdminTexts = () => async (dispatch) => {
  await axios
    .get('/api/texts')
    .then((res) => {
      dispatch(textAdminLoadAction(res.data));
    })
    .catch((_) => {
      dispatch(textErrorAction(adminLoadErrorMsg(modelName)));
    });
};

export const postText = (text) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(text);
  await axios
    .post('/api/texts', body, config)
    .then((res) => {
      dispatch(textPostAction(res.data));
    })
    .catch((_) => {
      dispatch(textErrorAction(adminPostErrorMsg(alertInsert)));
    });
};

export const patchText = (id, patchedFields) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(patchedFields);
  await axios
    .patch(`/api/texts/${id}`, body, config)
    .then((res) => {
      dispatch(textPatchAction(res.data));
    })
    .catch((_) => {
      dispatch(textErrorAction(adminPatchErrorMsg(alertInsert)));
    });
};

export const deleteText = (id) => async (dispatch) => {
  await axios
    .delete(`/api/texts/${id}`)
    .then(() => {
      dispatch(textDeleteAction(id));
    })
    .catch((_) => {
      dispatch(textErrorAction(adminDeleteErrorMsg(alertInsert)));
    });
};

export const clearTexts = () => async (dispatch) => {
  dispatch(textClearAction());
};
