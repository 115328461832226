/** @format */

import {
  ABSENCES_LOAD,
  ABSENCESALERT_LOAD,
  ABSENCE_ERROR,
  ABSENCE_USER_CLEAR,
} from '../actions/types';

const initialState = {
  users: [],
  count: 0,
  defaultIDX: 0,
  selectorFields: {
    field: '',
    level: '',
    course: '',
    classroom: '',
  },
};

export default function absence(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ABSENCES_LOAD:
      return { ...state, users: payload };
    case ABSENCESALERT_LOAD:
      return { ...state, count: payload.count, users: payload.users };
    case ABSENCE_ERROR:
      return state;
    case ABSENCE_USER_CLEAR:
      return { ...state, users: [] };
    default:
      return state;
  }
}
