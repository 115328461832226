import {
  CHATS_LOAD,
  MESSAGES_LOAD,
  MESSAGE_ADD,
  CHAT_SETCURRENT,
  CHAT_UPDATE,
  CHAT_ERROR,
  CHAT_CLEAR,
  MESSAGES_CLEAR,
} from '../actions/types';

const initialState = {
  conversations: [],
  messages: [],
  conversationsCount: 0,
  messagesCount: 0,
  currentChat: null,
};

const sortMessages = (messages) =>
  [
    ...new Map(
      messages.map((m) => [m._id.code ? m._id.code : m._id, m])
    ).values(),
  ].sort(
    (messageA, messageB) =>
      new Date(messageA.updatedAt) - new Date(messageB.updatedAt)
  );

export default function chat(state = initialState, action) {
  const { type, payload } = action;
  const { conversations, messages, currentChat } = state;
  let { messagesCount } = state;

  switch (type) {
    case MESSAGES_LOAD:
      return {
        ...state,
        messagesCount: payload.count,
        messages: sortMessages([...payload.messages, ...messages]),
      };
    case CHATS_LOAD:
      return {
        ...state,
        conversationsCount: payload.count,
        conversations: sortMessages([...payload.messages, ...conversations]),
      };
    case MESSAGE_ADD:
      if (currentChat.code === payload.code) {
        return {
          ...state,
          messagesCount: messagesCount++,
          messages: [...messages, payload],
        };
      } else {
        return state;
      }
    case CHAT_SETCURRENT:
      return {
        ...state,
        currentChat: payload,
      };
    case CHAT_UPDATE:
      return {
        ...state,
        conversations: sortMessages([
          ...conversations.filter(({ _id, code }) =>
            _id.code ? _id.code !== payload.code : code !== payload.code
          ),
          payload,
        ]),
      };
    case MESSAGES_CLEAR:
      return {
        ...state,
        messages: initialState.messages,
      };
    case CHAT_CLEAR:
      return initialState;
    case CHAT_ERROR:
      return state;
    default:
      return state;
  }
}
