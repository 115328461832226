import { INTERNSHIPS_LOAD, INTERNSHIPS_STATS_LOAD } from '../actions/types';

const initialState = {
  internships: [],
  internshipsStatistics: [],
  count: 0,
};

export default function internship(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INTERNSHIPS_LOAD:
      return { ...state, ...payload };
    case INTERNSHIPS_STATS_LOAD:
      return { ...state, internshipsStatistics: payload };
    default:
      return state;
  }
}
