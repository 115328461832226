import axios from 'axios';
import { toast } from 'react-toastify';

import {
  STUDENT_REGISTRATION_INIT,
  STUDENT_REGISTRATION_CLEAR,
  STUDENT_SINGLE_LOAD,
  STUDENT_SINGLE_CLEAR,
  STUDENT_DETAILS_UPDATE,
} from './types';

import { adminLoadErrorMsg, adminPatchErrorMsg } from './alertUtils';

const modelName = 'Inscription';
const alertInsert = `${modelName.toLowerCase()}`;

const registrationInitAction = (payload) => {
  return {
    type: STUDENT_REGISTRATION_INIT,
    payload,
  };
};

const singleStudentLoadAction = (payload) => {
  return {
    type: STUDENT_SINGLE_LOAD,
    payload,
  };
};

const clearSingleStudentAction = () => {
  return {
    type: STUDENT_SINGLE_CLEAR,
  };
};

const updateDetailsAction = (msg) => {
  toast.success(msg);
  return {
    type: STUDENT_DETAILS_UPDATE,
  };
};

const registrationClearAction = () => {
  return {
    type: STUDENT_REGISTRATION_CLEAR,
  };
};

const userErrorAction = (msg, action) => {
  toast.error(msg);
  return {
    payload: action,
  };
};

export const regsitrationInit = (payload) => (dispatch) => {
  dispatch(registrationInitAction(payload));
};

export const loadMe = () => async (dispatch) => {
  axios
    .get(`/api/users/me/registering`)
    .then((res) => {
      dispatch(registrationInitAction(res.data));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminLoadErrorMsg(alertInsert)));
    });
};

export const loadSingleStudent = (id) => (dispatch) => {
  axios
    .get(`/api/users/${id}`)
    .then((res) => {
      dispatch(singleStudentLoadAction(res.data));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminLoadErrorMsg(modelName), 'load'));
    });
};

export const updateDetails =
  (details, step, setIsPendingToFalse, handleNext) => (dispatch) => {
    axios
      .patch(`/api/users/me/registering/${step}`, details)
      .then((res) => {
        if (details.state) {
          dispatch(updateDetailsAction('Votre dossier a été soumis !'));
        } else {
          dispatch(updateDetailsAction('Dossier sauvegardé avec succès !'));
        }
        dispatch(loadMe());

        if (setIsPendingToFalse) setIsPendingToFalse();
        if (handleNext) handleNext();
      })
      .catch((err) => {
        userErrorAction(adminPatchErrorMsg(modelName), 'update');
        if (setIsPendingToFalse) setIsPendingToFalse();
      });
  };

export const updateDetailsForAdmin = (id, data, step) => (dispatch) => {
  axios
    .patch(`/api/users/${id}/registering/${step}`, data)
    .then((res) => {
      if (data.state) {
        dispatch(updateDetailsAction('Mise à jour effectuée'));
      } else {
        dispatch(
          updateDetailsAction('modifications sauvegardées avec succès !')
        );
      }
      dispatch(loadSingleStudent(id));
    })
    .catch((err) => {
      userErrorAction(adminPatchErrorMsg(modelName), 'update');
    });
};

export const registrationClear = () => (dispatch) => {
  dispatch(registrationClearAction());
};

export const clearSingleStudent = () => (dispatch) => {
  dispatch(clearSingleStudentAction());
};
