import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRouter from './components/routing/PrivateRouter';
import PublicRouter from './components/routing/PublicRouter';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

/*
 * Temporarly import splitted styles here, we need to discuss
 * about files architecture.
 * See : https://fr.reactjs.org/docs/faq-structure.html
 */
import './styles/Titlelight.css';
import './styles/History.css';
import './styles/Formation.css';
import './styles/NotFound.css';
import './styles/BurgerMenu.css';
import './styles/Navbar.css';
import './styles/Chapters.css';
import './styles/Steps.css';
import './styles/Form.css';
import './styles/Courses.css';
import './styles/Messenger.css';
import './styles/Books.css';
import './styles/Loans.css';
import './styles/Alumni.css';
import './styles/Internship.css';
import './styles/Statistics.css';
import './styles/MultiStep.css';
import './styles/Input.css';
// Not found page
const NotFound = lazy(() => import('./components/public/NotFound'));

const PublicLayout = lazy(() => import('./components/layout/PublicLayout'));

const EmailConfirmation = lazy(() =>
  import('./components/public/auth/EmailConfirmation')
);

const PrivateLayout = lazy(() => import('./components/layout/PrivateLayout'));

const Document = lazy(() => import('./components/private/admin/Document'));

const SigningList = lazy(() =>
  import('./components/private/admin/composition/SigningList')
);
const PaymentsList = lazy(() =>
  import('./components/private/admin/composition/PaymentsList')
);
const CodeList = lazy(() =>
  import('./components/private/admin/composition/CodeList')
);
const NoSkipperList = lazy(() =>
  import('./components/private/admin/absences/NoSkipperList')
);
const SkipperStats = lazy(() =>
  import('./components/private/admin/absences/SkipperStats')
);
const RegistrationNumberForm = lazy(() =>
  import('./components/private/admin/registration/RegistrationNumberForm')
);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const documents = [
  {
    path: '/noskippers/:courseCode/:threshold',
    subDocument: NoSkipperList,
  },
  {
    path: '/skipperstats/:courseCode/:threshold',
    subDocument: SkipperStats,
  },
  {
    path: '/signing/:courseCode/:testroom/:isDistributed',
    subDocument: SigningList,
  },
  { path: '/payment/:courseCode', subDocument: PaymentsList },
  { path: '/codes/:courseCode', subDocument: CodeList },
  {
    path: '/registration/:registrationNumber',
    subDocument: RegistrationNumberForm,
  },
];

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div>Chargement...</div>}>
          <Switch>
            {documents.map(({ path, subDocument }) => (
              <PrivateRouter
                key={path}
                path={path}
                component={() => <Document SubDocument={subDocument} />}
              />
            ))}

            <Route path='/404'>
              <NotFound />
            </Route>

            <Route
              path='/emailConfirmation/:token'
              component={EmailConfirmation}
            />

            <PrivateRouter path='/dashboard' component={PrivateLayout} />

            <PublicRouter path='/' component={PublicLayout} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
