import axios from 'axios';
import { toast } from 'react-toastify';
import {
  adminLoadErrorMsg,
  adminPostErrorMsg,
  adminPostSuccessMsg,
  adminPatchErrorMsg,
  adminPatchSuccessMsg,
  adminDeleteErrorMsg,
  adminDeleteSuccessMsg,
} from './alertUtils';

import {
  ARTICLES_LOAD,
  ARTICLE_LOAD,
  ARTICLE_POST,
  ARTICLE_PATCH,
  ARTICLE_DELETE,
  ARTICLE_ERROR,
  ARTICLE_CLEAR,
} from './types';

const modelName = 'Article';
const alertInsert = `de l'${modelName.toLowerCase()}`;

// actions
const loadArticlesAction = (loadedArticles, isAdmin) => {
  return {
    type: ARTICLES_LOAD,
    payload: loadedArticles,
    isAdmin,
  };
};
const loadSingleArticleAction = (loadedArticle) => {
  return {
    type: ARTICLE_LOAD,
    payload: loadedArticle,
  };
};
const articlePostAction = (postedArticle) => {
  toast.success(adminPostSuccessMsg(alertInsert));
  return {
    type: ARTICLE_POST,
    payload: postedArticle,
  };
};
const articlePatchAction = (patchedArticle) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: ARTICLE_PATCH,
    payload: patchedArticle,
  };
};
const deleteArticleAction = (deleteArticleId) => {
  toast.success(adminDeleteSuccessMsg(alertInsert));
  return {
    type: ARTICLE_DELETE,
    payload: deleteArticleId,
  };
};
const articleErrorAction = (msg) => {
  toast.error(msg);
  return {
    type: ARTICLE_ERROR,
  };
};
const articleClearAction = () => {
  return {
    type: ARTICLE_CLEAR,
  };
};

// Operations
export const loadArticles = (page, limit, isAdmin) => async (dispatch) => {
  await axios
    .get(`/api/articles${isAdmin ? '/admin' : ''}/${page}/${limit}`)
    .then((res) => {
      dispatch(loadArticlesAction(res.data, isAdmin));
    })
    .catch(() => {
      // No alerts for no-admin users
      if (isAdmin) {
        dispatch(articleErrorAction(adminLoadErrorMsg(modelName)));
      }
    });
};

export const loadArticle = (slug, isAdmin) => async (dispatch) => {
  await axios
    .get(`/api/articles${isAdmin ? '/admin' : ''}/${slug}`)
    .then((res) => {
      dispatch(loadSingleArticleAction(res.data));
    })
    .catch((_) => {
      // No alerts for no-admin users
      if (isAdmin) {
        dispatch(articleErrorAction(adminLoadErrorMsg(modelName)));
      }
    });
};

export const postArticle = (article) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(article);
  await axios
    .post('/api/articles', body, config)
    .then((res) => {
      dispatch(articlePostAction(res.data));
    })
    .catch((_) => {
      dispatch(articleErrorAction(adminPostErrorMsg(alertInsert)));
    });
};

export const patchArticle = (id, patchedFields) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(patchedFields);
  await axios
    .patch(`/api/articles/${id}`, body, config)
    .then((res) => {
      dispatch(articlePatchAction(res.data));
    })
    .catch((_) => {
      dispatch(articleErrorAction(adminPatchErrorMsg(alertInsert)));
    });
};

export const deleteArticle = (id) => async (dispatch) => {
  await axios
    .delete(`/api/articles/${id}`)
    .then(() => {
      dispatch(deleteArticleAction(id));
    })
    .catch((_) => {
      dispatch(articleErrorAction(adminDeleteErrorMsg(alertInsert)));
    });
};

export const clearArticles = () => async (dispatch) => {
  dispatch(articleClearAction());
};
