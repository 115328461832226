import {
  NEWSLETTER_SUBSCRIBE_SUCCESS,
  NEWSLETTER_SUBSCRIBE_ERROR,
} from '../actions/types';

const initialState = {};

/*
 * This reducer has been added to use the leverage of redux
 * for firing some notifications (react-toastify).
 */
export default function newsletter(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case NEWSLETTER_SUBSCRIBE_SUCCESS:
    case NEWSLETTER_SUBSCRIBE_ERROR:
      return state;
    default:
      return state;
  }
}
