// ERRORS
export const adminLoadErrorMsg = (msgInsert) =>
  `Les données "${msgInsert}" n'ont pas pu être chargées !`;
export const adminPostErrorMsg = (msgInsert) =>
  `Erreur lors de l'ajout ${msgInsert} !`;
export const adminPatchErrorMsg = (msgInsert) =>
  `Erreur de sauvegarde des modifications ${msgInsert} !`;
export const adminDeleteErrorMsg = (msgInsert) =>
  `Erreur lors de la suppression ${msgInsert} !`;

// SUCCESS
export const adminPostSuccessMsg = (msgInsert) =>
  `Ajout ${msgInsert} effectué !`;
export const adminPatchSuccessMsg = (msgInsert) =>
  `Modification ${msgInsert} effectuée !`;
export const adminDeleteSuccessMsg = (msgInsert) =>
  `Suppression ${msgInsert} effectuée !`;