import { PERMISSION_CHECK } from './types';

const checkPermissionAciton = (user) => {
  return {
    type: PERMISSION_CHECK,
    payload: user,
  };
};

export const checkPermission = (user) => (dispatch) => {
  dispatch(checkPermissionAciton(user));
};
