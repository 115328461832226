import {
  SLIDE_LOAD,
  SLIDE_ADMIN_LOAD,
  SLIDE_POST,
  SLIDE_PATCH,
  SLIDE_DELETE,
  SLIDE_ERROR,
  SLIDE_CLEAR,
} from '../actions/types';

const initialState = {
  slides: [],
  sliderNamesEnum: [],
};

export default function slide(state = initialState, action) {
  const { type, payload } = action;
  const { slides } = state;

  switch (type) {
    case SLIDE_LOAD:
      return { ...state, slides: [...slides, ...payload] };
    case SLIDE_ADMIN_LOAD:
      return { ...state, ...payload };
    case SLIDE_POST:
      return { ...state, slides: [...slides, payload] };
    case SLIDE_DELETE:
      return { ...state, slides: slides.filter(({ _id }) => _id !== payload) };
    case SLIDE_PATCH:
      return {
        ...state,
        slides: [...slides.filter(({ _id }) => _id !== payload._id), payload],
      };
    case SLIDE_CLEAR:
      return initialState;
    case SLIDE_ERROR:
      return state;
    default:
      return state;
  }
}
