import {
  PARTNERS_LOGO_LOAD,
  PARTNERS_LOGO_POST,
  PARTNERS_LOGO_PATCH,
  PARTNERS_LOGO_DELETE,
  PARTNERS_LOGO_ERROR,
  PARTNERS_LOGO_CLEAR,
} from '../actions/types';

const initialState = {
  partnersLogos: [],
};
const getDisplayableImages = (logos) => {
  return logos.map((logo) => {
    return Object.assign({}, logo, {
      key: logo.alt,
      src: `data:image/jpeg;base64,${logo.image}`,
    });
  });
};

export default function partnersLogo(state = initialState, action) {
  const { type, payload } = action;
  const { partnersLogos } = state;

  switch (type) {
    case PARTNERS_LOGO_LOAD:
      return {
        ...state,
        partnersLogos: getDisplayableImages(payload),
      };
    case PARTNERS_LOGO_POST:
      return { ...state, partnersLogos: [...partnersLogos, payload] };
    case PARTNERS_LOGO_DELETE:
      return {
        ...state,
        partnersLogos: partnersLogos.filter(({ _id }) => _id !== payload),
      };
    case PARTNERS_LOGO_PATCH:
      return {
        ...state,
        partnersLogos: [
          ...partnersLogos.filter(({ _id }) => _id !== payload._id),
          payload,
        ],
      };
    case PARTNERS_LOGO_ERROR:
      return state;
    case PARTNERS_LOGO_CLEAR:
      return initialState;
    default:
      return state;
  }
}
