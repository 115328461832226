import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import * as actionCreators from './actions/article';
import { environments } from './utils/enums';

const initialState = {};

const middleWare = [thunk];
let enhancer = applyMiddleware(...middleWare);

if (process.env.NODE_ENV !== environments.PROD) {
  const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25,
  });
  enhancer = composeEnhancers(enhancer);
}

const store = createStore(rootReducer, initialState, enhancer);

export default store;
