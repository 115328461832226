import {
  ROLE_LOAD,
  ROLE_POST,
  ROLE_PATCH,
  ROLE_DELETE,
  ROLE_ERROR,
  ROLE_CLEAR,
} from '../actions/types';

const initialState = {
  roles: [],
};

export default function role(state = initialState, action) {
  const { type, payload } = action;
  const { roles } = state;

  switch (type) {
    case ROLE_LOAD:
      return { ...state, roles: payload };
    case ROLE_POST:
      return { ...state, roles: [...roles, payload] };
    case ROLE_DELETE:
      return {
        ...state,
        roles: roles.filter(({ _id }) => _id !== payload),
      };
    case ROLE_PATCH:
      return {
        ...state,
        roles: [...roles.filter(({ _id }) => _id !== payload._id), payload],
      };
    case ROLE_CLEAR:
      return initialState;
    case ROLE_ERROR:
      return state;
    default:
      return state;
  }
}
