import { CONTACT_MAIL_SUCCESS, CONTACT_MAIL_ERROR } from '../actions/types';

const initialState = {};

/*
 * This reducer has been added to use the leverage of redux
 * for firing some notifications (react-toastify).
 */
export default function contact(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case CONTACT_MAIL_SUCCESS:
    case CONTACT_MAIL_ERROR:
      return state;
    default:
      return state;
  }
}
