import axios from 'axios';
import { toast } from 'react-toastify';
import {
  adminLoadErrorMsg,
  adminPostErrorMsg,
  adminPostSuccessMsg,
  adminPatchErrorMsg,
  adminPatchSuccessMsg,
  adminDeleteErrorMsg,
  adminDeleteSuccessMsg,
} from './alertUtils';

import {
  ROLE_LOAD,
  ROLE_POST,
  ROLE_PATCH,
  ROLE_DELETE,
  ROLE_ERROR,
  ROLE_CLEAR,
} from './types';

const modelName = 'Role';
const alertInsert = `de l'${modelName.toLowerCase()}`;

const roleLoadAction = (loadedRoles) => {
  return {
    type: ROLE_LOAD,
    payload: loadedRoles,
  };
};
const rolePostAction = (postedRole) => {
  toast.success(adminPostSuccessMsg(alertInsert));
  return {
    type: ROLE_POST,
    payload: postedRole,
  };
};
const rolePatchAction = (patchedRole) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: ROLE_PATCH,
    payload: patchedRole,
  };
};
const roleDeleteAction = (deletedRoleId) => {
  toast.success(adminDeleteSuccessMsg(alertInsert));
  return {
    type: ROLE_DELETE,
    payload: deletedRoleId,
  };
};
const roleErrorAction = (msg) => {
  toast.error(msg);
  return {
    type: ROLE_ERROR,
  };
};
export const roleClearAction = () => {
  return {
    type: ROLE_CLEAR,
  };
};

export const loadRoles = (isAdmin) => async (dispatch) => {
  await axios
    .get('/api/roles')
    .then((res) => {
      dispatch(roleLoadAction(res.data));
    })
    .catch((_) => {
      // No alerts for no-admin users
      if (isAdmin) {
        dispatch(roleErrorAction(adminLoadErrorMsg(modelName)));
      }
    });
};

export const postRole = (role) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(role);
  await axios
    .post('/api/roles', body, config)
    .then((res) => {
      dispatch(rolePostAction(res.data));
    })
    .catch((_) => {
      dispatch(roleErrorAction(adminPostErrorMsg(alertInsert)));
    });
};

export const patchRole = (id, patchedFields) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(patchedFields);
  await axios
    .patch(`/api/roles/${id}`, body, config)
    .then(async (res) => {
      dispatch(rolePatchAction(res.data));
      if (Object.keys(patchedFields).includes('order')) {
        dispatch(roleClearAction());
        await axios
          .get('/api/roles')
          .then((res) => {
            dispatch(roleLoadAction(res.data));
          })
          .catch((_) => {
            dispatch(roleErrorAction(adminLoadErrorMsg(modelName)));
          });
      }
    })
    .catch((_) => {
      dispatch(roleErrorAction(adminPatchErrorMsg(alertInsert)));
    });
};

export const deleteRole = (id) => async (dispatch) => {
  await axios
    .delete(`/api/roles/${id}`)
    .then(async () => {
      dispatch(roleDeleteAction(id));
      dispatch(roleClearAction());
      await axios
        .get('/api/roles')
        .then((res) => {
          dispatch(roleLoadAction(res.data));
        })
        .catch((_) => {
          dispatch(roleErrorAction(adminLoadErrorMsg(modelName)));
        });
    })
    .catch((_) => {
      dispatch(roleErrorAction(adminDeleteErrorMsg(alertInsert)));
    });
};

export const clearRoles = () => async (dispatch) => {
  dispatch(roleClearAction());
};
