import {
  PENALTY_LOAD,
  PENALTY_POST,
  PENALTY_PATCH,
  PENALTY_DELETE,
  PENALTY_ERROR,
  PENALTY_CLEAR,
  PENALTY_CURRENT_STUDENT_CHANGE,
} from '../actions/types';

const initialState = {
  students: [],
  penalties: [],
  currentStudent: {},
  currentStudentChanged: false,
  count: 0,
};

/*
 * We do this because of Footer which is loaded multiple times when
 * moving from page to page.
 */

export default function penalty(state = initialState, action) {
  const { type, payload } = action;
  const { students } = state;

  switch (type) {
    case PENALTY_LOAD:
      return {
        ...state,
        students: payload.students ?? [],
        penalties: payload.penalties ?? [],
        currentStudent: payload.student ?? {},
        count: payload.count,
      };
    case PENALTY_POST:
      return {
        ...state,
        penalties: [payload, ...state.penalties],
        students: students.map(({ conductNote, ...rest }) => ({
          ...rest,
          conductNote: conductNote - payload.value,
        })),
      };
    case PENALTY_PATCH:
      const toUpdate = state.penalties.filter(({ _id }) => _id === payload._id);
      return {
        ...state,
        penalties: [
          ...state.penalties.filter(({ _id }) => _id !== toUpdate[0]._id),
          payload,
        ],
        students: students.map(({ conductNote, ...rest }) => ({
          ...rest,
          conductNote: conductNote + toUpdate[0].value - payload.value,
        })),
      };
    case PENALTY_DELETE:
      const toDelete = state.penalties.filter(({ _id }) => _id === payload);
      return {
        ...state,
        penalties: state.penalties.filter(({ _id }) => _id !== payload),
        students: students.map(({ conductNote, ...rest }) => ({
          ...rest,
          conductNote: conductNote + toDelete[0].value,
        })),
      };
    case PENALTY_CURRENT_STUDENT_CHANGE:
      return { ...state, currentStudentChanged: payload };
    case PENALTY_CLEAR:
      return initialState;
    case PENALTY_ERROR:
      return state;
    default:
      return state;
  }
}
