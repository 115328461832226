/** @format */

import axios from 'axios';
import { toast } from 'react-toastify';
import {
  adminLoadErrorMsg,
  adminPostErrorMsg,
  adminPostSuccessMsg,
  adminPatchErrorMsg,
  adminPatchSuccessMsg,
  adminDeleteErrorMsg,
  adminDeleteSuccessMsg,
} from './alertUtils';

import {
  USER_LOADING,
  USER_LOAD,
  USER_POST,
  USER_PATCH,
  USERS_PATCH_STATE,
  USERS_CLASS_ALLOCATION,
  USER_DELETE,
  USER_ERROR,
  USER_CLEAR,
  USER_FORM_CLEAR,
} from './types';

import { httpCodes } from '../utils/enums';

const modelName = 'Utilisateur';
const alertInsert = `de l'${modelName.toLowerCase()}`;

// actions
const userLoadingAction = () => {
  return {
    type: USER_LOADING,
  };
};
// structure of payload : { users, count }
const userLoadAction = (payload) => {
  return {
    type: USER_LOAD,
    payload,
  };
};

const userPostAction = (postedUser, password) => {
  toast.success(
    adminPostSuccessMsg(alertInsert) +
      ' Les instrcutions de validation sont envoyées dans votre boite mail.'
  );
  return {
    type: USER_POST,
    payload: { user: postedUser, password },
  };
};

const userPatchAction = (patchedUser, patchedFields) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: USER_PATCH,
    payload: { user: patchedUser, fields: patchedFields },
  };
};

const userPatchStatesAction = (students) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: USERS_PATCH_STATE,
    payload: students,
  };
};

const userClassAllocationAction = (students) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: USERS_CLASS_ALLOCATION,
    payload: students,
  };
};

const userPatchStudentMissingInfoAction = (patchedUser, patchedFields) => {
  toast.success(adminPatchSuccessMsg(alertInsert));
  return {
    type: USER_PATCH,
    payload: { user: patchedUser, fields: patchedFields },
  };
};

const userDeleteAction = (deletedUserId) => {
  toast.success(adminDeleteSuccessMsg(alertInsert));
  return {
    type: USER_DELETE,
    payload: { user: { _id: deletedUserId } },
  };
};
const userErrorAction = (msg, action) => {
  toast.error(msg);
  return {
    type: USER_ERROR,
    payload: action,
  };
};
const userClearAction = () => {
  return {
    type: USER_CLEAR,
  };
};
const userFormClearAction = () => {
  return {
    type: USER_FORM_CLEAR,
  };
};

// Operations
export const loadUsers = (role, page, limit, filter) => async (dispatch) => {
  dispatch(userLoadingAction());
  await axios
    .get(`/api/users/${role}/${page}/${limit}`, {
      params: role !== 'student' ? {} : filter,
    })
    .then((res) => {
      dispatch(userLoadAction(res.data));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminLoadErrorMsg(modelName), 'load'));
    });
};

export const postUser = (user) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(user);
  await axios
    .post('/api/users', body, config)
    .then((res) => {
      dispatch(userPostAction(res.data, user.password));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminPostErrorMsg(alertInsert), 'post'));
    });
};

const errorMessages = {
  registrationNotFound: "Le code d'inscription n'est pas reconnu",
  registrationBadReq: "Le code d'inscription est déjà utilisé",
  email: 'Votre addresse email a déjà été utilisé',
  phoneNumber: 'Votre numéro de téléphone a déjà été utilisé',
  forbidden: `Vous n'êtes pas autorisé à effectuer cette action.
     Veuillez recharger la page, s'il vous plaît.`,
};
export const postStudent = (user, setPendingToFalse) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(user);
  axios
    .post('/api/users/student', body, config)
    .then((res) => {
      dispatch(userPostAction(res.data, user.password));
      if (setPendingToFalse) setPendingToFalse();
    })
    .catch((err) => {
      if (!err) dispatch(userErrorAction(adminPostErrorMsg(alertInsert)));

      const errorStatus = err.response?.status;
      const apiErrorMessage = err.response?.data?.error;
      let errorMessage = '';

      if (apiErrorMessage.includes('registration')) {
        if (errorStatus === httpCodes.NOT_FOUND)
          errorMessage = errorMessages['registrationNotFound'];

        if (errorStatus === httpCodes.BAD_REQUEST)
          errorMessage = errorMessages['registrationBadRequest'];
      }

      if (errorStatus === httpCodes.CONFLICT) {
        if (apiErrorMessage.includes('email'))
          errorMessage = errorMessages['email'];

        if (apiErrorMessage.includes('phoneNumber'))
          errorMessage = errorMessages['phoneNumber'];
      }

      if (errorStatus === httpCodes.FORBIDDEN) {
        errorMessage = errorMessages['forbidden'];
      }

      dispatch(userErrorAction(errorMessage, 'post'));
      if (setPendingToFalse) setPendingToFalse();
    });
};

export const patchUser = (id, patchedFields) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(patchedFields);
  await axios
    .patch(`/api/users/${id}`, body, config)
    .then((res) => {
      dispatch(userPatchAction(res.data, patchedFields));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminPatchErrorMsg(alertInsert), 'patch'));
    });
};

export const patchUserStudentMissingInfo =
  (id, patchedFields) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify(patchedFields);
    await axios
      .patch(`/api/users/minssinginfo/${id}`, body, config)
      .then((res) => {
        dispatch(userPatchStudentMissingInfoAction(res.data, patchedFields));
      })
      .catch((_) => {
        dispatch(userErrorAction(adminPatchErrorMsg(alertInsert), 'patch'));
      });
  };

export const patchUserStates = (students, state) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ students, state });
  dispatch(userLoadingAction());

  await axios
    .patch('/api/users/students', body, config)
    .then((res) => {
      dispatch(userPatchStatesAction(students));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminPatchErrorMsg(alertInsert), 'patch'));
    });
};

export const patchRetro = (students) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ students });
  dispatch(userLoadingAction());

  await axios
    .patch('/api/users/students/retrograde', body, config)
    .then((res) => {
      dispatch(userPatchStatesAction(students));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminPatchErrorMsg(alertInsert), 'patch'));
    });
};

export const userClassAllocate = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  dispatch(userLoadingAction());

  await axios
    .patch('/api/users/students/distribution', body, config)
    .then((res) => {
      dispatch(userClassAllocationAction(res.data));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminPatchErrorMsg(alertInsert), 'patch'));
    });
};

export const deleteUser = (id) => async (dispatch) => {
  await axios
    .delete(`/api/users/${id}`)
    .then((res) => {
      dispatch(userDeleteAction(id));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminDeleteErrorMsg(alertInsert), 'delete'));
    });
};

export const clearUsers = () => async (dispatch) => {
  dispatch(userClearAction());
};

export const clearUserForm = () => async (dispatch) => {
  dispatch(userFormClearAction());
};
